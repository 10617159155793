import * as React from "react"

import Layout from "../components/layout"
import Hero from "../components/hero"

const IndexPage = () => (
  <Layout page="Index">
    <Hero />
  </Layout>
)

export default IndexPage
