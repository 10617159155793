import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import useCards from "../hooks/use-cards"

import { Card } from "./card"

const Hero = () => {
  const cards = useCards()

  const data = useStaticQuery(graphql`
    query HeadingQuery {
      allContentfulHero {
        nodes {
          heroImage {
            gatsbyImageData(width: 100, placeholder: BLURRED)
          }
          subtitle1 {
            subtitle1
          }
          heading1
          heading2
          heading3
        }
      }
    }
  `)

  const { heroImage, heading1, heading2, heading3, subtitle1, subtitle2 } =
    data.allContentfulHero.nodes[0]

  return (
    <div className="w-full flex flex-col justify-center items-center min-h-screen">
      <div className="w-full flex flex-col lg:flex-row items-center justify-center mt-2 px-4">
        <div class="rounded-full border-transparent ml-2 h-24 w-24 flex items-center justify-center md:transform md:scale-125 md:-translate-x-8">
          <GatsbyImage
            className="rounded-full"
            image={heroImage.gatsbyImageData}
          />
        </div>
        <div className="flex flex-col lg:pl-4 space-y-2 py-4 ">
          <h1>{heading1}</h1>
          <h2 className="whitespace-nowrap">
            {heading2}
            <br />
            {heading3}.
          </h2>
          <p className="subtitle">
            {subtitle1.subtitle1}.
            <br className="md:hidden" />
            {/* {subtitle2} */}
          </p>
        </div>
      </div>
      <div className="flex flex-col lg:flex-row items-center justify-center mt-2 lg:mt-16 2xl:mt-32">
        {cards.map(
          card =>
            card.link === "/about" && (
              <Card
                key={card.id}
                image={card.icon}
                text={card.cardText}
                link={card.link}
              />
            )
        )}
        {cards.map(
          card =>
            card.link === "/portfolio" && (
              <Card
                key={card.id}
                image={card.icon}
                text={card.cardText}
                link={card.link}
              />
            )
        )}
        {cards.map(
          card =>
            card.link === "/contact" && (
              <Card
                key={card.id}
                image={card.icon}
                text={card.cardText}
                link={card.link}
              />
            )
        )}
      </div>
    </div>
  )
}

export default Hero
